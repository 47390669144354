<template>
  <div></div>
</template>

<script>
import Storage from "../../../models/storage";

export default {
  name: "GoogleAuth",
  mounted() {
    const code = this.$route.query.code;
    if (code) {
      this.authorize(code);
    } else {
      console.error("URL 中找不到 code 參數");
    }
  },
  methods: {
    async authorize(code) {
      const vm = this;

      vm.$store.state.isloading = true;
      if (code == "" || code == null) {
        await vm.$router.push("/login");
      }

      let user = vm.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      //accountBind(快速連結)
      if (user == null && Storage.get("accountBind") != undefined) {
        userId = Storage.get("accountBind").id;
        Storage.remove("accountBind");
      }

      let par = { AuthCode: code, UserId: userId };

      await vm.$store
        .dispatch("auth/googleLogin", par)
        .then(() => {
          let name = vm.$store.state.auth.user.name;
          let message = name === "" ? "登入成功" : `${name}，歡迎回來！`;
          vm.notify("success", message);
          vm.$router.push(Storage.get("ChargeMe_Setting").page);
        })
        .catch(() => {
          Storage.remove("user");
          vm.notify("error", "登入失敗！");
          vm.$router.push("/login")
        })
        .finally(() => {
          vm.$store.state.isloading = false;
        });
    },
  },
};
</script>

<style scoped></style>
